import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './pages/songPages.css'
import {Helmet} from "react-helmet";
import Home from './pages/home/home.jsx'
// import Listen from './pages/listen/listen.jsx';
// import Live from './pages/live/live.jsx';
// import Watch from './pages/watch/watch.jsx';
import Navbar from './component/navbar/navbar.jsx';
import Footer from './component/footer/footer.jsx';
// import Gallery from './pages/gallery/gallery.jsx';
// import SinkToTheLevel from './pages/sinkToTheLevel/sinkToTheLevel.jsx';
// import Pandemonium from './pages/pandemonium/pandemonium.jsx';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy.jsx';
import HomeSong from './pages/homeSong/homeSong';
import AboveFromBelow from './pages/aboveFromBelow/aboveFromBelow';
 
const Listen = React.lazy(() => import('./pages/listen/listen.jsx'));
const Watch = React.lazy(() => import('./pages/watch/watch.jsx'));
const Live = React.lazy(() => import('./pages/live/live.jsx'));
const Gallery = React.lazy(() => import('./pages/gallery/gallery.jsx'));
const Links = React.lazy(() => import('./pages/links/links.jsx'));
const SinkToTheLevel = React.lazy(() => import('./pages/sinkToTheLevel/sinkToTheLevel.jsx'));
const Pandemonium = React.lazy(() => import('./pages/pandemonium/pandemonium.jsx'));
const EmergencySong = React.lazy(() => import('./pages/emergencySong/emergencySong.jsx'));
const DailyRoutine = React.lazy(() => import('./pages/dailyRoutine/dailyRoutine.jsx'));
const ConsumeMe = React.lazy(() => import('./pages/consumeMe/consumeMe.jsx'));
const HomeEPK = React.lazy(() => import('./pages/pressReleases/homeEPK.jsx'));


class App extends Component {
  render() {
    return (  
      <div>
        <Helmet>
          <title>Midlight</title>
          <meta
            name="description"
            content="Midlight. Alternative rock band based in Brighton/London. Listen. Live. Watch."
          />
          <link rel="canonical" href="https://midlighthq.com" />
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name ="robots" content="index,follow"></meta>
          <meta property="og:url" content="https://midlighthq.com" />
          <meta property="og:title" content="Midlight" />
          <meta property="og:description" content="Midlight. Alternative rock band based in Brighton/London. Listen. Live. Watch." />
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="1493861641001652" />
        </Helmet>   
       <BrowserRouter>
          <div className='row g-0 router'>
              <div className='col-12 text-center'>
                <Navbar />
                </div>
          </div> 
          <div className='row g-0 router'>
              <div className='col-12 text-center p-0'>
              <Suspense fallback={<div></div>}>
                <Routes>
                <Route exact path='/' element={<Home />}/>
                <Route path='/listen' element={<Listen />}/>
                <Route path='/live' element={<Live />}/>
                <Route path='/watch' element={<Watch />}/>
                <Route path='/gallery' element={<Gallery />}/>
                <Route path='/links' element={<Links />}/>
                <Route path='/sink-to-the-level' element={<SinkToTheLevel />}/>
                <Route path='/pandemonium' element={<Pandemonium />}/>
                <Route path='/emergency-song' element={<EmergencySong />}/>
                <Route path='/daily-routine' element={<DailyRoutine />}/>
                <Route path='/consume-me' element={<ConsumeMe />}/>
                <Route path='/home' element={<HomeSong />}/>
                <Route path='/above-from-below' element={<AboveFromBelow />}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
                <Route path='/epk-home-sGuGhML6UH' element={<HomeEPK />}/>
                </Routes>
              </Suspense>
                </div>
          </div>  
          <div className='row g-0 router'>
              <div className='offset-1 col-10 text-center'>
                <Footer />
                </div>
          </div>  
        </BrowserRouter>
        </div> 
    );
  }
}

export default App;