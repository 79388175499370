import React, { Component } from "react";
import { Icon } from '@iconify/react';
import spotifyIcon from '@iconify/icons-mdi/spotify';

class SpotifyIcon extends Component {
    render() {
      return (
            <Icon icon={spotifyIcon} width='24' height='24'/>
      )
    }
}

export default SpotifyIcon