import React, { Component } from "react";
import { Icon } from '@iconify/react';
import soundcloudIcon from '@iconify/icons-mdi/soundcloud';

class SoundCloudIcon extends Component {
    render() {
      return (
            <Icon icon={soundcloudIcon} width='24' height='24'/>
      )
    }
}

export default SoundCloudIcon