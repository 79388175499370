import React, {  } from 'react';
import './navbar.css'
import {
    NavLink
  } from "react-router-dom";
//   import { ReactComponent as MidlightOrange } from '../../media/images/MIDLIGHT-LOGO-ORANGE.svg'
  import MidlightLogo from '../../media/images/MIDLIGHT-LOGO-ORANGE.png'
//   import { ReactComponent as MidlightGreen } from '../../media/images/MIDLIGHT-LOGO-GREEN.svg'

const Navbar = (props) => {
    return (
        <div className='navbar-wrapper'>
              <div className='row g-0 nav'>
                  <div className='offset-lg-1 offset-md-1 offset-sm-1 col-lg-10 col-md-10 col-sm-10'>
                      <ul className="navbar-nav">
                      <li className="nav-item active">
                          <div className="nav-title"><NavLink to="/">
                          {/* <MidlightOrange className='title-logo' /> */}
                          <img src={MidlightLogo} alt='MIDLIGHT' className='title-logo'></img>
                              </NavLink></div>
                      </li>
                      </ul>
                  </div>
              </div>

              <nav className="navbar navbar-expand-lg navbar-dark">
                      
              <div className='row g-0 nav'>
              <div className='offset-2 col-8 text-center'>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerMain" aria-controls="navbarTogglerMain" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                      </button>
              
              <div className="collapse navbar-collapse" id="navbarTogglerMain">
                  <div className='offset-lg-2 offset-md-0 offset-sm-0 col-lg-2 col-md-12 col-sm-12 text-center'>
                      <ul className="navbar-nav">
                      <li className="nav-item">
                          <div className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/listen">Listen</NavLink></div>
                      </li>
                      </ul>
                      </div>

                      <div className='offset-md-0 offset-sm-0 col-lg-2 col-md-12 col-sm-12 text-center'>
                      <ul className="navbar-nav">
                      <li className="nav-item">
                          <div className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/watch">Watch</NavLink></div>
                      </li>
                      </ul>
                      </div>

                      <div className='offset-md-0 offset-sm-0 col-lg-2 col-md-12 col-sm-12 text-center'>
                  <ul className="navbar-nav">
                  <li className="nav-item">
                      <div className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/live">Live</NavLink></div>
                  </li>
                  </ul>
                  </div>

                  {/* <div className='offset-md-0 offset-sm-0 col-lg-2 col-md-12 col-sm-12 text-center'>
                  <ul className="navbar-nav">
                  <li className="nav-item">
                      <div className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/news">News</NavLink></div>
                  </li>
                  </ul>
                  </div> */}

                  <div className='offset-md-0 offset-sm-0 col-lg-2 col-md-12 col-sm-12 text-center'>
                  <ul className="navbar-nav">
                  <li className="nav-item">
                      <div className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"><a href="https://midlight.bandcamp.com/merch" target="_blank" rel="noopener noreferrer">Merch</a></div>
                  </li>
                  </ul>
                  </div>
              </div>
          </div>
          </div>
          </nav>
            </div>
            );
        }

export default Navbar;