import React from 'react';
import './footer.css';
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SpotifyIcon from '../icons/spotifyIcon'
import SoundCloudIcon from '../icons/soundcloudIcon'


const Footer = (props) => {
      return(
        <div className="footer">
        <div className='hidden-div'>
        <div className='row g-0'>
        <div className='offset-lg-0 col-lg-3 col-md-12 col-sm-12 text-center socials'>
        <div className='footer-list-wrapper'>
            <div className='footer-list'>
            <ul className='footer-list'>
            <li><a href='https://facebook.com/midlighthq' target='_blank' rel="noopener noreferrer" className='footer-list'>
                        <FacebookIcon />
                        </a></li>
            <li><a href='https://www.instagram.com/midlight.ig/' target='_blank' rel="noopener noreferrer" className='footer-list'>
                        <InstagramIcon />
                        </a></li>
            <li><a href='https://twitter.com/midlightHQ' target='_blank' rel="noopener noreferrer" className='footer-list'>
                        <TwitterIcon />
                        </a></li>
            <li><a href='https://open.spotify.com/artist/18rC6DW5QiOZQ0Du7nRAdu?si=qIJ11ApCTpaxE7xv8GAtEA' target='_blank' rel="noopener noreferrer" className='footer-list'>
                        <SpotifyIcon />
                        </a></li>
            <li><a href='https://soundcloud.com/midlight-hq' target='_blank' rel="noopener noreferrer" className='footer-list'>
                        <SoundCloudIcon />
                        </a></li>
            <li><a href='https://www.youtube.com/channel/UCaWRl7vOBArlMMUAzzRRpeg' target='_blank' rel="noopener noreferrer" className='footer-list'>
                        <YouTubeIcon />
                        </a></li>
            <li><a href='mailto:info@midlighthq.com?Subject=Hello!' target='_blank' rel="noopener noreferrer" className='footer-list'>
                        <MailOutlineIcon />
                        </a></li>
            </ul>
            </div>
            </div>
        </div>
        {/* <div className='offset-md-0 offset-sm-0 offset-lg-6 col-lg-3 col-md-12 col-sm-12 pt-4 subscribe'>
            <div className='footerHeader'>
            Subscribe to our newsletter
            </div>
            <div id="mc_embed_signup">
                <form action="https://midlighthq.us16.list-manage.com/subscribe/post?u=c20e85d77dff12f25acc59836&amp;id=131dd64df1" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                
                <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email@example.com" required />

                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_c20e85d77dff12f25acc59836_131dd64df1" tabindex="-1" value="" /></div>
                <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                </div>
                </form>
                <div className='right-footer-list'>
                <ul className='right-footer-list'>
                <li className='privacy-policy'> We use Google Analytics. <NavLink to='/privacy-policy'>
                Privacy Notice
                </NavLink></li>
                </ul>
                </div>
            </div>
            </div> */}
        </div>
        {/* <div className='col-lg-3 col-md-3 col-sm-3'>
            <div className='spotify-follow'>
        <iframe src="https://open.spotify.com/follow/1/?uri=spotify:artist:18rC6DW5QiOZQ0Du7nRAdu?si=xMi3hVkdS7KWpzWy1Ikd1g&size=basic&theme=light&show-count=0" title='follow-button' width="200" height="30" scrolling="no" frameborder="0" style={{color:'black', border:'none', overflow:'hidden'}} allowtransparency="true"></iframe>
        </div>
        </div> */}
        </div>
    </div>
      )
  }


// const Footer = withRouter(FooterHide);
export default Footer