import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MusicEmbed from '../../component/musicEmbed/musicEmbed.jsx'
import links from '../songLinks.json'
import '../links/links.css'

class HomeSong extends Component {
    render() {
        return(
            <div className='consume-me'>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Midlight - Home</title>
                <meta name="description" content="Home - Midlight. Out now. Stream on Spotify, Apple Music, YouTube... I miss you, I miss you." />
                <link rel="canonical" href="https://midlighthq.com/home" />
            </Helmet>
            <div className='row g-0 m-0 raise'>
                    <div className='offset-lg-3 col-lg-6 col-md-12 col-sm-12 text-center p-0'>
                    <div className='song-page-subtitle'>Home</div>
                    <div className='song-page-subsubtitle'>Out now on all platforms</div>

                    <div className='row g-0 m-0 raise'>
                <div className='offset-lg-3 col-lg-6 col-md-12 col-sm-12 text-center'>
                    <div className='raise' style={{'padding-top': '1rem', 'padding-bottom':'0rem'}} >
                    <MusicEmbed url={links.home.spotifyEmbed} />
                    </div>
                    </div>
                    </div>


                    <div className='row g-0 m-0 raise'>
                    <div className='col-lg-4 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
                    <a className='listen-link-song-page' href={links.home.spotify}>Spotify</a>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
                    <a className='listen-link-song-page' href={links.home.bandcamp}>Bandcamp</a>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
                    <a className='listen-link-song-page' href={links.home.apple}>Apple Music</a>
                    </div>
                    {/* <div className='col-lg-3 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
                    <a className='listen-link-song-page' href='https://music.amazon.co.uk/albums/B08NRXS292?trackAsin=B08NSJFNV4'>Amazon Music</a>
                    </div> */}
                    </div>
                    {/* <div className='row g-0'>
                    <div className='offset-lg-3 col-lg-6 col-md-12 col-sm-12 text-center' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
                    <a className='listen-link-song-page raise' href=''>Buy limited 10" vinyl here</a>
                    </div>
                    </div> */}
                </div>
                </div>

                <div className='row g-0 raise mb-4 m-0 raise'>
                <div className='offset-lg-3 col-lg-6 col-md-12 col-sm-12 text-center'>
                    <div id='youtube-player' className='col-lg-12 col-md-12 col-sm-12 text-center'>                    
                    <iframe title='consume-me' width="100%" height="315" src={links.home.youtubeEmbed} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" ></iframe>
                    </div>
                    </div>
                    </div>

                    {/* <div className='row g-0'>
                        <div className='offset-lg-4 offset-sm-0 offset-md-0 col-lg-4 col-md-12 col-sm-12 text-center'>
                            <div className='img-wrapper-song-page'>
                                    <img
                                className="d-block w-100"
                                src={require("../../media/images/dr-artwork-final.jpg")}
                                alt="Daily Routine"
                                />
                            </div>
                            </div>
            </div> */}

            {/* <div className='row g-0 mb-4 raise'>
                
                <div className='offset-lg-3 col-lg-6 col-md-12 col-sm-12 text-center links-dark'>
                <div className='links-subtitle'>Press Round Up</div>
                <div className='row g-0 mt-4 mb-4'>
                <div className='col-lg col-md-12 col-sm-12 text-center raise links-dark p-4'>
                <a className='links-button' href={links.consumeMe.press.zine}>The Zine UK</a>
                </div>
                <div className='col-lg col-md-12 col-sm-12 text-center raise links-dark p-4'>
                <a className='links-button' href={links.consumeMe.press.rcm}>Right Chord</a>
                </div>
                <div className='col-lg col-md-12 col-sm-12 text-center raise links-dark p-4'>
                <a className='links-button' href={links.consumeMe.press.nms}>New Music Social</a>
                </div>
                </div>
            </div>
            </div> */}

                
            </div>
        )
    }
}


export default HomeSong