import React, {Component} from 'react';
import './musicEmbed.css'
 
class MusicEmbed extends Component {
    render() { 
        return (
            
            <div id='music-embed' className='container-fluid raise'>
                <iframe src={this.props.url} title='music-embed' width="75%" height="260px" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
    );
    }
}
 
export default MusicEmbed;


