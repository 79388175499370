import React, { Component } from 'react';
import './home.css';
import {Helmet} from "react-helmet";
import links from '../songLinks.json'
import MusicEmbed from '../../component/musicEmbed/musicEmbed.jsx'
// import LiveDates from '../../component/liveDates/liveDates';

class Home extends Component {
    render() {
        return(
    <div className="homeSection">
        <Helmet>
                <meta charSet="utf-8" />
                <title>Midlight</title>
                <meta name="description" content="Midlight. Alternative rock band based in Brighton/London. Listen. Live. Watch." />
                <link rel="canonical" href="https://midlighthq.com" />

            </Helmet>

            <div className='row g-0'>
                <div className='offset-lg-4 col-lg-4 col-md-12 col-sm-12 text-center raise p-0'>
                    <div className='listen-subtitle'>Exit - Out Now</div>
                    <MusicEmbed url={links.exit.spotifyEmbed} />
                    <a className='listen-link' href={links.exit.spotify}>Spotify</a>
                    {/* <a className='listen-link' href={links.exit.bandcamp}>Bandcamp</a> */}
                    <a className='listen-link' href={links.exit.apple}>Apple</a>
                    <a className='listen-link' href={links.exit.links}>More</a>
                </div>
                </div>

            {/* <div className='row g-0'>
                        <div className='offset-lg-4 offset-sm-0 offset-md-0 col-lg-4 col-md-12 col-sm-12 text-center'>
                            <div className='img-wrapper-song-page'>
                                    <img
                                className="d-block w-100"
                                src={require("../../media/images/another-day-artwork.jpg")}
                                alt="Another Day artwork"
                                />
                            </div>
                            </div>
            </div> */}

    </div>
        )
    }
}

export default Home