import React, { Component } from 'react';
import './aboveFromBelow.css';
import {Helmet} from "react-helmet";
import MusicEmbed from '../../component/musicEmbed/musicEmbed.jsx'
import links from '../songLinks.json'
// import {
//     NavLink,
//   } from "react-router-dom";
class AboveFromBelow extends Component {
    render() {
        return(
    <div className="homeSection">
        <Helmet>
                <meta charSet="utf-8" />
                <title>Midlight - Above From Below</title>
                <meta name="description" content="Midlight debut EP - Above from Below" />
                <link rel="canonical" href="https://midlighthq.com/above-from-below" />
            </Helmet>
            <div className='row g-0 pt-4'>
            <div className='offset-lg-3 col-lg-6 offset-sm-1 col-sm-10 offset-md-1 col-md-10 p-4'>
                <div className='announce-header-home'>Debut EP 'Above from Below' Out Now</div>
                {/* <div className='announce-header-home'><NavLink className='home-navlink' to='/home'>HOME - OUT NOW</NavLink></div> */}
            </div>    
            </div>
            <div className='row g-0 m-0 raise'>
            <div className='offset-lg-3 col-lg-6 col-md-12 col-sm-12 text-center'>
            <div className='' style={{'padding-top': '1rem', 'padding-bottom':'0rem'}} >
            <MusicEmbed url={links.aboveFromBelow.spotifyEmbed} />
            </div>
            </div>
            </div>


            <div className='row g-0 m-0 '>
            <div className='offset-lg-3 col-lg-2 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
            <a className='listen-link-song-page raise' href={links.aboveFromBelow.spotify}>Spotify</a>
            </div>
            <div className='col-lg-2 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
            <a className='listen-link-song-page raise' href={links.aboveFromBelow.bandcamp}>Bandcamp</a>
            </div>
            <div className='col-lg-2 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
            <a className='listen-link-song-page raise' href={links.aboveFromBelow.apple}>Apple Music</a>
            </div>
            {/* <div className='col-lg-3 col-md-12 col-sm-12' style={{'padding': '0px', 'padding-top': '1rem', 'margin-top': '1rem'}}>
            <a className='listen-link-song-page' href='https://music.amazon.co.uk/albums/B08NRXS292?trackAsin=B08NSJFNV4'>Amazon Music</a>
            </div> */}
            </div>
            <div className='row g-0'>
            <div className='offset-lg-3 col-lg-6 offset-sm-1 col-sm-10 offset-md-1 col-md-10 mt-4'>
                Limited run of CDs on our Bandcamp
                </div>
                </div>
            <div className='row g-0'>
            <div className='offset-lg-3 col-lg-6 offset-sm-1 col-sm-10 offset-md-1 col-md-10'>
                <img
            className="w-100"
            src={require("../../media/images/afb-cds.png")}
            alt="afb-cds"
            />
        </div>
        </div>
        </div>
        )
    }
}

export default AboveFromBelow